.header {
    display: flex;
    flex-direction: column; /* Stack navigation and row content */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.row {
    display: flex;
    flex: 1;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    gap: 2rem;
}

.column {
    flex: 1;
    text-align: center;
}

.profile-card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid var(--grey);
    margin-right: 2rem;
    margin-top: 1rem;
}

.header-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align */
    justify-content: center;
    text-align: center;
    gap: 0.5rem; /* Add spacing between lines */
}

.name {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0; /* Remove extra margin */
    white-space: nowrap; /* Prevent text wrapping */
}

.job-title {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--dark-grey);
    margin: 0; /* Remove extra margin */
    white-space: nowrap; /* Prevent text wrapping */
}

.header-link {
    width: 64px;
    height: 64px;
    object-fit: contain;
    margin: 1rem;
}

.header-links {
    display: flex; /* Use flexbox for alignment */
    flex-direction: row; /* Arrange logos in a row */
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Align the icons vertically */
    gap: 1rem; /* Add spacing between the logos */
    margin-top: 1rem; /* Optional: Adjust spacing from the rest of the header */
}

.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    background-color: #f9f9f9; /* Optional: Light background for separation */
    border-bottom: 1px solid #e0e0e0; /* Optional: Subtle border */
    margin-bottom: 1rem; /* Spacing between nav and row */
    width: 100%;
}

.navigation a {
    margin: 0 15px;
    text-decoration: none;
    color: #4a4a4a;
    font-weight: bold;
    font-size: 1rem;
}

.navigation a:hover {
    color: #007bff;
    text-decoration: underline;
}
