/* Styles for the Caption Generator */
.caption-generator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.caption-generator h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
}

.caption-generator input {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.caption-generator input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
}

.caption-generator button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.caption-generator button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.caption-generator button:hover:not(:disabled) {
    background-color: #0056b3;
}

.generated-caption {
    margin-top: 1.5rem;
    font-size: 1.2rem;
    font-style: italic;
    color: #333333;
    text-align: center;
    word-break: break-word;
}

.drop-zone {
    width: 100%;
    height: 200px;
    border: 2px dashed #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer;
    background-color: #f9f9f9;
}

.drop-zone:hover {
    background-color: #f1f1f1;
}

.preview-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
}
