:root {
  --white: #ffffff;
  --light-grey: #f5f5f5;
  --grey: #ededed;
  --dark-grey: #8b8b8b;
  --black: #484848;
}

body {
  font-family: 'Roboto', sans-serif;
  color: var(--black);
  background-color: var(--light-grey);
  margin: 0;
  padding: 0;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 2rem;
}

.card {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: transform 0.2s ease-in-out;
}

footer {
  margin-top: 1rem;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 0px 20px;
  border-radius: 8px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  position: relative;
  z-index: 10;
  width: 100%;
  /* max-width: 700px; */
  /* margin: 0 auto 0 auto; */
}

.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  /* max-width: 700px; */
  margin: 0 auto;
  padding: 20px;
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pdf-viewer {
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}

.pdf-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
}

.pdf-nav button {
  background-color: var(--dark-grey);
  color: var(--white);
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s;
  font-weight: bold;
}

.pdf-nav button:hover {
  background-color: var(--black);
}
